export const environment = {
  production: true,
  version: (require('../version.json')) as {version: string},
  xds: {
    apiRoot: 'https://test.beeceen.com/api/'
  },
  keycloak: {
    realm: 'xplace',
    url: 'https://keycloak.dev.k8s.xplace.mmst.eu/auth',
    clientId: 'xds-store-frontend'
  },
};
